import LOCALES from 'utils/locales';
import {
  sideMenu,
  button,
  labels as label,
  placeHolders as placeHolder,
  title,
  errors as error,
  messages as message,
  text,
} from '../label';

const sideMenuLabels = {
  [sideMenu.analytics]: 'Analytics',
  [sideMenu.workers]: 'Workers',
  [sideMenu.configurations.workerRoles]: 'Worker Roles',
  [sideMenu.configurations.subContractors]: 'Subcontractors',
  [sideMenu.mapsZones]: 'Maps & Zones',
  [sideMenu.productivity]: 'eTQR',
  [sideMenu.crewsWorkshifts]: 'Crews & Workshifts',
  [sideMenu.crews]: 'Crews',
  [sideMenu.workshifts]: 'WorkShifts',
  [sideMenu.nodes]: 'Nodes Management',
  [sideMenu.installationMode]: 'Installation Mode',
  [sideMenu.maintance.title]: 'Maintenance Kit',
  [sideMenu.maintance.mapView]: 'Map View',
  [sideMenu.maintance.mapViewTitle]: 'Maintenance Toolkit',
  [sideMenu.userManagement]: 'User Management',
  [sideMenu.configurations.title]: 'Configurations',
  [sideMenu.configurations.bulkUploads]: ' Bulk Uploads',
  [sideMenu.safetyDemo]: 'Safety Demo',
  [sideMenu.electricalRmInstallation]: 'ELECTRICAL RM INSTALLATION',
  [sideMenu.safety]: 'Safety',
  [sideMenu.evacuation]: 'Evacuation',
  [sideMenu.videoAI]: 'Video AI',
  [sideMenu.videoAICamera]: 'Cameras',
  [sideMenu.videoAIGallery]: 'Incident Gallery',
  [sideMenu.videoAIReports]: 'Incident & Reports',
  [sideMenu.dailyAttendanceReport]: 'Daily Attendance Report',
};

const buttonLabels = {
  [button.create]: 'Create',
  [button.cancel]: 'Cancel',
  [button.update]: 'Update',
  [button.logout]: 'Logout',
  [button.proceed]: 'Proceed',
  [button.upload]: 'Upload',
  [button.uploadExcel]: 'Upload Excel',
  [button.reUploadExcel]: 'Re-Upload Excel',
  [button.addNewClient]: 'Add New Client',
  [button.yes]: 'Yes',
  [button.no]: 'No',
  [button.confirm]: 'Confirm',
  [button.add]: 'Add',
  [button.continue]: 'Continue',
  [button.startEvacuation]: 'Start Evacuation',
  [button.stopEvacuation]: 'Stop Evacuation',
  [button.submit]: 'Submit',
};

const labels = {
  [label.name]: 'Name',
  [label.role]: 'Role',
  [label.mobileNumber]: 'Mobile Number',
  [label.roleName]: 'Role Name',
  [label.subcontractorName]: 'Subcontractor Name',
  [label.helmet]: 'Helmet',
  [label.helmetColor]: 'Helmet Color',
  [label.workerTagId]: 'Worker Tag ID',
  [label.contractor]: 'Contractor',
  [label.selectLanguage]: 'Select Language',
  [label.clientName]: 'Client Name',
  [label.address]: 'Address',
  [label.crew]: 'Crew',
  [label.workshift]: 'Workshift',
  [label.crewDetails]: 'Crew Details',
  [label.workshiftDetails]: 'Workshift Details',
  [label.dateFrom]: 'Date From',
  [label.downloadTemplate]: 'Download Template',
  [label.activity]: 'Activity',
  [label.siteEngineer]: 'SITE ENGINEER',
  [label.foreMan]: 'FOREMAN NAME',
  [label.dateTo]: 'Date To',
  [label.downloadCreateCrewTemplate]: 'Download Create Crew Template',
};

const placeHolders = {
  [placeHolder.name]: 'Enter Name',
  [placeHolder.mobile]: 'Enter Mobile Number',
  [placeHolder.roleName]: 'Enter Role Name',
  [placeHolder.workerName]: 'Worker Name',
  [placeHolder.selectRole]: 'Select Role',
  [placeHolder.selectHelmet]: 'Select Helmet',
  [placeHolder.selectHelmetId]: 'Select Helmet ID',
  [placeHolder.selectContractor]: 'Select Contractor',
  [placeHolder.selectSubContractor]: 'Select Subcontractor',
  [placeHolder.selectSite]: 'Select Site',
  [placeHolder.tagId]: 'Tag ID',
  [placeHolder.selectClient]: 'Select Client',
  [placeHolder.selectBuilding]: 'Select Building',
  [placeHolder.workshiftName]: 'Enter Workshift Name',
  [placeHolder.category]: 'Select Category',
  [placeHolder.crewName]: 'Enter Crew Name',
  [placeHolder.crewId]: 'Enter Crew ID',
  [placeHolder.crew]: 'Select Crew',
  [placeHolder.workshift]: 'Select Workshift',
  [placeHolder.searchWorkshiftName]: 'Workshift Name',
  [placeHolder.searchWorkers]: 'Search workers',
  [placeHolder.filterByBuilding]: 'Filter by Building',
  [placeHolder.filterByContractor]: 'Filter by Subcontractor',
  [placeHolder.filterByRole]: 'Filter by Role',
  [placeHolder.filterByCrew]: 'Filter by Crew',
  [placeHolder.filterByStatus]: 'Filter by Status',
  [placeHolder.filterByContractorGroup]: 'Filter by Subcontractor Groups',
  [placeHolder.filterByRoleGroup]: 'Filter by Worker Role Groups',
  [placeHolder.filterByCrewsGroup]: 'Filter by Crew Groups',

  [placeHolder.searchCrews]: 'Crew Name/ID',
  [placeHolder.searchZones]: 'Search Zones',
  [placeHolder.searchNodes]: 'Search Nodes',

  [placeHolder.selectSafeZone]: 'Select Safe Zone',
  [placeHolder.selectFloor]: 'Select Floor',
  [placeHolder.selectZone]: 'Select Zone',
  [placeHolder.selectAssemblyPoint]: 'Select Assembly Point',
};
const titles = {
  [title.worker]: 'Worker',
  [title.addWorker]: 'Add Worker',
  [title.editWorker]: 'Edit Worker',
  [title.workerRole]: 'Worker Role',
  [title.addWorkerRole]: 'Add Worker Role',
  [title.editWorkerRole]: 'Edit Worker Role',
  [title.subcontractor]: 'Subcontractor',
  [title.addSubcontractor]: 'Add Subcontractor',
  [title.editSubcontractor]: 'Edit Subcontractor',
  [title.createClient]: 'Create Client',
  [title.workerName]: 'Worker Name',
  [title.role]: 'Role',
  [title.tagId]: 'Tag ID',
  [title.helmetID]: 'Helmet ID',
  [title.building]: 'Building',
  [title.floor]: 'Floor',
  [title.zone]: 'Zone',
  [title.lastSeen]: 'Last Seen',
  [title.actions]: 'Actions',
  [title.crew]: 'Crew',
  [title.workShift]: 'Workshift',
  [title.joinedDate]: 'Joined Date',
  [title.joinDate]: 'Join Date',
  [title.location]: 'Location',
  [title.site]: 'Site',
  [title.quickStats]: 'Quick Stats',
  [title.totalWorkers]: 'Total Workers',
  [title.deployedHelmets]: 'Deployed Helmets',
  [title.assignedHelments]: 'Assigned Helmets',
  [title.onlineHelmets]: 'Online Assigned Helmets',
  [title.activeHelmets]: 'Active Helmets',
  [title.inactiveHelmets]: 'Inactive Helmets',
  [title.withoutHelmets]: 'without Helmets',
  [title.allDeployedHelmets]: 'All deployed helmets at site',
  [title.assignedHelmetsOnly]: 'Assigned helmets only',
  [title.onlineHelmetsInfo]: 'Total online helmets',
  [title.activeHelmetsInfo]: 'Active assigned helmets shown',
  [title.inactiveHelmetsInfo]: 'Inactive assigned helmets shown',
  [title.totalAssets]: 'Total Assets',
  [title.totalAnchors]: 'Total Anchors',
  [title.totalSinks]: 'Total Sinks',
  [title.onlineAssets]: 'Online Assets',
  [title.onlineAnchors]: 'Online Anchors',
  [title.onlineSinks]: 'Online Sinks',
  [title.trackWorkers]: 'Track Workers',
  [title.active]: 'Active',
  [title.inactive]: 'Inactive',
  [title.return]: 'Return',
  [title.zoom]: 'Zoom',
  [title.zoneName]: 'Zone Name',
  [title.addWorkshift]: 'Add Workshift',
  [title.workshiftName]: 'Workshift Name',
  [title.category]: 'Category',
  [title.nonWorkingDays]: 'Non Working Days',
  [title.duration]: 'Duration',
  [title.startTime]: 'Start Time',
  [title.endTime]: 'End Time',
  [title.breakStartTime]: 'Break Start Time',
  [title.breakEndTime]: 'Break End Time',
  [title.breakStartTimeWithNumber]: 'Break {name} Start Time',
  [title.breakEndTimeWithNumber]: 'Break {name} End Time',
  [title.addCrew]: 'Add Crew',
  [title.crewId]: 'Crew ID',
  [title.crewName]: 'Crew Name',
  [title.assignHelmet]: 'Assign Helmet',
  [title.unAssignHelmet]: 'Unassign Helmet',
  [title.breaks]: 'Breaks',
  [title.noWorkers]: 'There are no workers on the crew.',
  [title.locatedOn]: 'Located',
  [title.workshiftDetails]: 'Workshift Details',
  [title.bulkUploadTitle]: 'Build Upload - Create Workers',
  [title.downloadTemplate]: 'Download Worker Excel Template',
  [title.downloadCrewWorkshiftTemplate]: 'Download Assignments to Workshifts and Crews Template',
  [title.clearTrack]: 'Clear Tracking',
  [title.createdAt]: 'Created Date',
  [title.numberOfWorkers]: 'Number of Workers',
  [title.bulkAssignCrewShifts]: 'Bulk Upload | Assignments to Workshifts and Crews',
  [title.toolName]: 'Tool Name',
  [title.locationGroup]: 'Location Group',
  [title.locationGroupTagID]: 'Location Group ID',
  [title.locationGroupName]: 'Location Group Name',
  [title.template]: 'Template',
  [title.subcontractorsGroup]: 'Subcontractors Group',
  [title.download]: 'Download',
  [title.bulkSubcontractorsGroup]: 'Bulk Upload | Assign Subcontractors to Subcontracts Group',
  [title.crewsGroup]: 'Crews Group',
  [title.bulkCrewsGroup]: 'Bulk Upload | Assign Crews to Crews Group',
  [title.locationGroupAssignment]: 'Assign Workers to Location Groups',
  [title.workerRolesGroup]: 'Worker Roles Group',
  [title.updateWorkerStatus]: 'Worker Status',
  [title.uploadReportedHours]: 'Upload Reported Hours',
  [title.reportedHours]: 'Reported Hours',
  [title.reportedWorkHours]: 'Bulk Upload | Reported Hours for Workers',
  [title.assignCrewManager]: 'Assign Crew Manager',
  [title.assignCrewToCrewManager]: 'Bulk Upload | Assign Crew to Crew Manager',
  [title.downloadCrewAssignmentTemplate]: 'Download Crew Assignment Excel Template',
  [title.date]: 'Date',
  [title.siteEngineerName]: 'Site Engineer Name',
  [title.foremanName]: 'Foreman Name',
  [title.sectionDescription]: 'Section Description',
  [title.activityCode]: 'Activity Code',
  [title.activityCodes]: 'Activity Codes',
  [title.activityDescription]: 'Activity Description',
  [title.uom]: 'UOM',
  [title.targetQTY]: 'Target QTY',
  [title.plannedQTY]: 'Planned QTY',
  [title.targetRate]: 'Target Rate (HR/U)',
  [title.targetHours]: 'Planned Hours',
  [title.targetQuantity]: 'Target Quantity',
  [title.bookedHours]: 'Booked Hours',
  [title.actualRate]: 'Actual Rate',
  [title.actualQTY]: 'Actual QTY',
  [title.actualProductivityRate]: 'Actual Productivity Rate',
  [title.gainLossHours]: 'Gain/Loss Hours',
  [title.delayCodes]: 'Delay Codes',
  [title.delayLostHours]: 'Delay Lost Hours',
  [title.status]: 'Status',
  [title.delays]: 'Delays ({count})',
  [title.reason]: 'Reason',
  [title.hoursLost]: 'Hours Lost',
  [title.notes]: 'Notes',
  [title.resetPassword]: 'Reset Password',
  [title.lastActive]: 'Last Active',
  [title.helmetAssignedDate]: 'Helmet Assigned Date',
  [title.lastUpdatedDate]: 'Last Updated Date',
  [title.confirmApproval]: 'Confirm Approval',
  [title.totalWorkingHours]: 'Total {name} Hours (per week)',
  [title.configureWorkingHours]: 'Configure Working Hours',
  [title.nonWorkingDay]: 'Non Working Day',
  [title.networkId]: 'Network ID',
  [title.nodeId]: 'Node ID',
  [title.nodeType]: 'Node Type',
  [title.bufferUsage]: 'Buffer Usage',
  [title.linkQuality]: 'Link Quality',
  [title.batteryVoltage]: 'Battery Voltage',
  [title.updatedAt]: 'Updated Date',
  [title.locationUpdatedAt]: 'Location Updated Date',
  [title.displacementError]: 'Displacement Error',
  [title.activeInactive]: 'Active / Inactive',
  [title.locationGroupCode]: 'Location Group Code',
  [title.unitOfMeasure]: 'Unit of Measure',
  [title.targetRatee]: 'Target Rate',
  [title.crewGroupName]: 'Crew Group Name',
  [title.workerRoleGroup]: 'Worker Role Group',
  [title.remarks]: 'Remarks',
  [title.workerStatus]: 'Worker Status',
  [title.subcontractorGroup]: 'Subcontractor Group',
  [title.reported]: 'Reported',
  [title.bulkUploadCrew]: 'Bulk Upload | Create Crew',
  [title.manualLocationAssignment]: 'Manual Location Assignment',
};

const texts = {
  [text.roles]: 'roles',
  [text.contractors]: 'contractors',
  [text.helmets]: 'helmets',
  [text.sites]: 'sites',
  [text.clients]: 'clients',
  [text.workers]: 'workers',
  [text.nA]: '-NA-',
  [text.infoMessage1Hour]: 'The data is from last 1 hour',
  [text.floors]: 'floors',
  [text.buildings]: 'buildings',
  [text.crews]: 'crews',
  [text.workshifts]: 'workshifts',
  [text.analytics]: 'analytics',
  [text.subcontractors]: 'subcontractors',
  [text.subcontractorgroups]: 'subcontractor groups',
  [text.rolegroups]: 'role groups',
  [text.crewgroups]: 'crew groups',
  [text.zones]: 'zones',
  [text.productivity]: 'Production Rate',
  [text.bookedHours]: 'Booked hrs',
  [text.actualHours]: 'Planned hrs',
  [text.lostHours]: 'Lost hrs',
  [text.tagIds]: 'tag IDs',
  [text.inventories]: 'nodes',
};

const errors = {
  [error.required]: '{name} is required',
  [error.min]: '{name} accepts a minimum of {val} characters',
  [error.max]: '{name} accepts a maximum of {val} characters',
  [error.minDigits]: '{name} accepts a minimum of {val} digits',
  [error.maxDigits]: '{name} accepts a maximum of {val} digits',
  [error.onlyDigits]: '{name} accepts only digits',
  [error.image]: `PNG, JPEG, JPG formats are allowed, image can't exceed 3MB`,
  [error.exceedDuration]: `Breaks duration can't exceed workshift duration`,
  [error.sequential]: `Breaks must be sequential`,
  [error.conflicting]: 'Breaks are conflicting with each other',
  [error.fallOutSide]: 'Break start or end time cannot fall outside workshift period',
  [error.uploadSizeExceed]: `File size can't exceed 10 MB`,
  [error.uploadType]: `File type isn't supported.`,
  [error.alreadyExist]: `{name} name already exists!`,
};

const messages = {
  [message.created]: '{name} is created successfully',
  [message.confirm]:
    'You are about to cancel all your updates. All changes will be unsaved, proceed?',
  [message.dataNA]: 'No {name} available',
  [message.unknowError]: 'Something went wrong, please contact support!',
  [message.tryAgain]: 'Something went wrong please try again',
  [message.noInternet]: 'No internet connection, please check your connection and try again',
  [message.internetBack]: `Internet is back!`,
  [message.deleteConfirm]: `Are you sure to delete {name} information, this action can't be undone?`,
  [message.deleteSuccess]: '{name} is deleted successfully',
  [message.unableToload]: 'Unable to load the blueprint',
  [message.pleaseTryAgain]: 'Please try again',
  [message.unAssignConfim]: 'Are you sure to unassign helmet?',
  [message.assigned]: 'The helmet is assigned successfully',
  [message.uploadSuccess]: 'Upload Success',
  [message.approveMessage]: 'Are you sure to approve the selected activities?',
};

export default {
  [LOCALES.ENGLISH]: {
    ...sideMenuLabels,
    ...buttonLabels,
    ...labels,
    ...placeHolders,
    ...titles,
    ...texts,
    ...errors,
    ...messages,
  },
};
