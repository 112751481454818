/**
 *  Users
 */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const USER_LOGOUT_USER_SUCCESS = 'USER_LOGOUT_USER_SUCCESS';
export const USER_LANG_SET = 'USER_LANG_SET';
export const USER_SET_LOADING = 'USER_SET_LOADING';
export const USER__LAST_SELECTION = 'User: USER__LAST_SELECTION';

/**
 *  Clients
 */

// View Clients
export const CLIENT__VIEW_CLIENT = 'CLIENT__VIEW_CLIENT';
export const CLIENT__LIST_CLIENTS_REQ = 'CLIENT__LIST_CLIENTS_REQ';
export const CLIENT__LIST_CLIENTS_SUCCESS = 'CLIENT__LIST_CLIENTS_SUCCESS';
export const CLIENT__LIST_CLIENTS_FAILED = 'CLIENT__LIST_CLIENTS_FAILED';
// Create Client
export const CLIENT__CREATE_CLIENT_REQ = 'CLIENT__CREATE_CLIENT_REQ';
export const CLIENT__CREATE_CLIENT_SUCCESS = 'CLIENT__CREATE_CLIENT_SUCCESS';
export const CLIENT__CREATE_CLIENT_FAILED = 'CLIENT__CREATE_CLIENT_FAILED';

/**
 * Sites
 */

export const SITES__VIEW_SITE = 'SITES__VIEW_SITE';
export const SITES__LIST_SITES_REQ = 'SITES__LIST_SITES_REQ';
export const SITES__LIST_SITES_SUCCESS = 'SITES__LIST_SITES_SUCCESS';
export const SITES__LIST_SITES_FAILED = 'SITES__LIST_SITES_FAILED';
export const SITES__CREATE_SITE_SUCCESS = 'SITES__CREATE_SITE_SUCCESS';

/**
 * SideModal
 */

export const SIDE_MODAL_TOGGLE = 'SIDE_MODAL_TOGGLE';

/**
 *  User Management (USMG__)
 */

export const USMG__USERS_LIST_REQ = 'USMG__USERS_LIST_REQ';
export const USMG__USERS_LIST_SUCCESS = 'USMG__USERS_LIST_SUCCESS';
export const USMG__USERS_LIST_FAILED = 'USMG__USERS_LIST_FAILED';
export const USMG__UPDATE_USERS_LIST = 'USMG__UPDATE_USERS_LIST';
export const USMG__DEACTIVATE_USER_REQ = 'USMG__DEACTIVATE_USER_REQ';
export const USMG__DEACTIVATE_USER_SUCCESS = 'USMG__DEACTIVATE_USER_SUCCESS';
export const USMG__DEACTIVATE_USER_FAILED = 'USMG__DEACTIVATE_USER_FAILED';
export const USMG__ACTIVATE_USER_REQ = 'USMG__ACTIVATE_USER_REQ';
export const USMG__ACTIVATE_USER_SUCCESS = 'USMG__ACTIVATE_USER_SUCCESS';
export const USMG__ACTIVATE_USER_FAILED = 'USMG__ACTIVATE_USER_FAILED';
export const USMG__RESEND_INVITATION_REQ = 'USMG__RESEND_INVITATION_REQ';
export const USMG__RESEND_INVITATION_SUCCESS = 'USMG__RESEND_INVITATION_SUCCESS';
export const USMG__RESEND_INVITATION_FAILED = 'USMG__RESEND_INVITATION_FAILED';
export const USMG__EDIT_USER_REQ = 'USMG__EDIT_USER_REQ';
export const USMG__EDIT_USER_SUCCESS = 'USMG__EDIT_USER_SUCCESS';
export const USMG__EDIT_USER_FAILED = 'USMG__EDIT_USER_FAILED';
export const USMG__CREATE_USER_REQ = 'USMG__CREATE_USER_REQ';
export const USMG__CREATE_USER_SUCCESS = 'USMG__CREATE_USER_SUCCESS';
export const USMG__CREATE_USER_FAILED = 'USMG__CREATE_USER_FAILED';
export const USMG__USER_CLIENT_LIST_REQ = 'USMG__USER_CLIENT_LIST_REQ';
export const USMG__USER_CLIENT_LIST_SUCCESS = 'USMG__USER_CLIENT_LIST_SUCCESS';
export const USMG__USER_CLIENT_LIST_FAILED = 'USMG__USER_CLIENT_LIST_FAILED';
export const USMG__RESET = 'USMG__RESET';

/**
 * Installation (IN__)
 */

export const IN__RESET = 'IN__RESET';

// Buildings.
export const IN__VIEW_BUILDING = 'IN__VIEW_BUILDING';
export const IN__LIST_BUILDINGS_REQ = 'IN__LIST_BUILDINGS_REQ';
export const IN__LIST_BUILDINGS_SUCCESS = 'IN__LIST_BUILDINGS_SUCCESS';
export const IN__LIST_BUILDINGS_FAILED = 'IN__LIST_BUILDINGS_FAILED';
export const IN__CREATE_BUILDING_SUCCESS = 'IN__CREATE_BUILDING_SUCCESS';
export const IN__CREATE_BUILDING_REQ = 'IN__CREATE_BUILDING_REQ';
export const IN__CREATE_BUILDING_FAILED = 'IN__CREATE_BUILDING_FAILED';
export const RESET_BUILDINGS = 'Installation: Reset Buildings';

// Floors
export const IN__VIEW_FLOOR_REQ = 'IN__VIEW_FLOOR_REQ';
export const IN__VIEW_FLOOR_SUCCESS = 'IN__VIEW_FLOOR_SUCCESS';
export const IN__VIEW_FLOOR_FAILED = 'IN__VIEW_FLOOR_FAILED';
export const IN__LIST_FLOORS_REQ = 'IN__LIST_FLOORS_REQ';
export const IN__LIST_FLOORS_SUCCESS = 'IN__LIST_FLOORS_SUCCESS';
export const IN__LIST_FLOORS_FAILED = 'IN__LIST_FLOORS_FAILED';
export const IN__CREATE_FLOOR_SUCCESS = 'IN__CREATE_FLOOR_SUCCESS';
export const IN__CREATE_FLOOR_REQ = 'IN__CREATE_FLOOR_REQ';
export const IN__CREATE_FLOOR_FAILED = 'IN__CREATE_FLOOR_FAILED';
export const IN__EDIT_FLOOR_SUCESS = 'IN__EDIT_FLOOR_SUCESS';
export const IN__EDIT_FLOOR_REQ = 'IN__EDIT_FLOOR_REQ';
export const IN__EDIT_FLOOR_FAILED = 'IN__EDIT_FLOOR_FAILED';
export const IN__DUPLICATE_FLOOR_REQ = 'IN__DUPLICATE_FLOOR_REQ';
export const IN__DUPLICATE_FLOOR_SUCCESS = 'IN__DUPLICATE_FLOOR_SUCCESS';
export const IN__DUPLICATE_FLOOR_FAILED = 'IN__DUPLICATE_FLOOR_FAILED';
export const IN__DELETE_FLOOR_REQ = 'IN__DELETE_FLOOR_REQ';
export const IN__DELETE_FLOOR_SUCCESS = 'IN__DELETE_FLOOR_SUCCESS';
export const IN__DELETE_FLOOR_FAILED = 'IN__DELETE_FLOOR_FAILED';
export const IN__SET_FLOOR_TO_EDIT = 'IN__SET_FLOOR_TO_EDIT';
export const IN__SET_CREATE_EDIT_FLOOR_VISIBLE = 'IN__SET_CREATE_EDIT_FLOOR_VISIBLE';
export const IN__RESET_FLOORS = 'IN__RESET_FLOORS';

/**
 * Workers
 *
 */
export const TOOGLE_WORKERS_FETCH = 'Workers: Toogle Worker List';
export const TOGGLE_WORKERVIEW_MODAL = 'Workers: Toogle Worker View Modal';
export const TOGGLE_WORKERCREATE_MODAL = 'Workers: Toogle Worker Create Modal';
export const UPDATE_WORKER_DETAILS = 'Workers: Update Worker Details';

export const VIEW_WORKER_REQ = 'Workers: View Worker Req';
export const VIEW_WORKER_SUCCESS = 'Workers: View Worker Success';
export const VIEW_WORKER_FAILED = 'Workers: View Worker Failed';

export const ONLINE_WORKERS_REQ = 'Workers: Online Workers Req';
export const ONLINE_WORKERS_SUCCESS = 'Workers: Online Workers Success';
export const ONLINE_WORKERS_FAILED = 'Workers: Online Workers Failed';

export const TOTAL_WORKERS_COUNT = 'Workers: Total Workers';
/**
 * Crews
 */

export const TOOGLE_CREWS_FETCH = 'Crews: Toogle Crews List';

// WorkShifts
export const IN__LIST_WORKSHIFTS_REQ = 'IN__LIST_WORKSHIFTS_REQ';
export const IN__LIST_WORKSHIFTS_SUCCESS = 'IN__LIST_WORKSHIFTS_SUCCESS';
export const IN__LIST_WORKSHIFTS_FAILED = 'IN__LIST_WORKSHIFTS_FAILED';

export const TOOGLE_WORKSHIFT_FETCH = 'WorkShifts: Toogle WorkShift List';

/**
 * MaintanceMap
 *
 */

export const MAINTANCE_MAP_TOGGLE_FULL_SCREEN = 'MaintanceMap: MAINTANCE_MAP_TOGGLE_FULL_SCREEN';
export const MAINTANCE_MAP_TOGGLE_PRESENTATION_VIEW =
  'MaintanceMap: MAINTANCE_MAP_TOGGLE_PRESENTATION_VIEW';
export const MAINTANCE_MAP_TOGGLE_ANCHOR_GRAPH_VIEW =
  'MaintanceMap: MAINTANCE_MAP_TOGGLE_ANCHOR_GRAPH_VIEW';
export const MAINTANCE_MAP_MAP_RESET = 'MaintanceMap: MAINTANCE_MAP_MAP_RESET';
export const MAINTANCE_MAP_IMAGE_LOADING = 'MaintanceMap: MAINTANCE_MAP_IMAGE_LOADING';
export const MAINTANCE_MAP_TOGGLE_PLAY_BACK_MODE =
  'MaintanceMap: MAINTANCE_MAP_TOGGLE_PLAY_BACK_MODE';
export const MAINTANCE_MAP_PLAY_BACK_TIME = 'MaintanceMap: MAINTANCE_MAP_PLAY_BACK_TIME';

export const MAINTANCE_MAP_SELECTED_FLOOR = 'MaintanceMap: Maintance Selection Selected Floor';
export const MAINTANCE_MAP_WNT_INVENTORYS = 'MaintanceMap: Maintance Map Inventories';
export const MAINTANCE_MAP_INVENTORYS_SEARCH = 'MaintanceMap: Search Inventories';

/**
 * Installation
 */

/**
 * Zones
 */

export const LIST_ZONES_REQ = 'Zones: List Zones Req';
export const LIST_ZONES_SUCCESS = 'Zones: List Zones Success';
export const LIST_ZONES_FAILED = 'Zones: List Zones Failed';
export const SEARCH_ZONE = 'Zones: Search Zones';
export const RESET_ZONE_SEARCH = 'Zones: Reset Zones';
export const VIEW_ZONE = 'Zones: View Zones';
export const RESET_ZONES = 'Zones: Reset Zones';
/**
 * Walls
 */
export const LIST_WALLS_REQ = 'Walls: List Walls Req';
export const LIST_WALLS_SUCCESS = 'Walls: List Walls Success';
export const LIST_WALLS_FAILED = 'Walls: List Walls Failed';
export const SEARCH_WALL = 'Walls: Search Walls';
export const RESET_WALL_SEARCH = 'Walls: Reset Walls';
export const VIEW_WALL = 'Walls: View Walls';
export const RESET_WALLS = 'Walls: Reset Walls';
/**
 * Anchors
 */
export const LIST_APPROVED_ANCHORS_REQ = 'Anchors: LIST_APPROVED_ANCHORS_REQ';
export const LIST_APPROVED_ANCHORS_SUCCESS = 'Anchors: LIST_APPROVED_ANCHORS_SUCCESS';
export const LIST_APPROVED_ANCHORS_FAILED = 'Anchors: LIST_APPROVED_ANCHORS_FAILED';

export const LIST_UNAPPROVED_ANCHORS_REQ = 'Anchors: LIST_UNAPPROVED_ANCHORS_REQ';
export const LIST_UNAPPROVED_ANCHORS_SUCCESS = 'Anchors: LIST_UNAPPROVED_ANCHORS_SUCCESS';
export const LIST_UNAPPROVED_ANCHORS_FAILED = 'Anchors: LIST_UNAPPROVED_ANCHORS_FAILED';

export const LIST_PLANNING_ANCHORS_REQ = 'Anchors: LIST_PLANNING_ANCHORS_REQ';
export const LIST_PLANNING_ANCHORS_SUCCESS = 'Anchors: LIST_PLANNING_ANCHORS_SUCCESS';
export const LIST_PLANNING_ANCHORS_FAILED = 'Anchors: LIST_PLANNING_ANCHORS_FAILED';

export const APPROVE_ANCHOR_REQ = 'Anchors: APPROVE_ANCHOR_REQ';
export const APPROVE_ANCHOR_SUCCESS = 'Anchors: APPROVE_ANCHOR_SUCCESS';
export const APPROVE_ANCHOR_FAILED = 'Anchors: APPROVE_ANCHOR_FAILED';

export const UNAPPROVE_ANCHOR_REQ = 'Anchors: UNAPPROVE_ANCHOR_REQ';
export const UNAPPROVE_ANCHOR_SUCCESS = 'Anchors: UNAPPROVE_ANCHOR_SUCCESS';
export const UNAPPROVE_ANCHOR_FAILED = 'Anchors: UNAPPROVE_ANCHOR_FAILED';

export const UPDATE_ANCHOR_LOCATION = 'Anchors: UPDATE_ANCHOR_LOCATION';
export const RESET_ANCHOR_SEARCH = 'Anchors: RESET_ANCHOR_SEARCH';

export const ANCHOR_VIEW_ANCHOR = 'Anchors: ANCHOR_VIEW_ANCHOR';
export const ANCHOR_SEARCH_ANCHORS = 'Anchors: ANCHOR_SEARCH_ANCHORS';
export const ANCHOR_CLEAR_ANCHORS = 'Anchors: ANCHOR_CLEAR_ANCHORS';

export const LIST_APPROVED_INVENTORY_REQ = 'Anchors: LIST_APPROVED_INVENTORY_REQ';
export const LIST_APPROVED_INVENTORY_SUCCESS = 'Anchors: LIST_APPROVED_INVENTORY_SUCCESS';
export const LIST_APPROVED_INVENTORY_FAILED = 'Anchors: LIST_APPROVED_INVENTORY_FAILED';

/**
 * WorkerRoles
 */

export const TOOGLE_WORKER_ROLES_FETCH = 'WorkerRoles: Toogle WorkerRoles List';

/**
 * Subcontracts
 */

export const TOOGLE_SUBCONTRACTORS_FETCH = 'Subcontractors: Toogle SubContractors List';

/**
 * Safety
 */

export const SET_IS_SAFETY_EVACUATION = 'Safety: Set Is Safety Evacuation';
export const SET_SAFE_ZONE = 'Safety: Set Safe Zone';
export const SET_SAFETY_EVENTS = 'Safety: Set Safety Events';
export const SET_SELECTED_EVENT = 'Safety: Set Selected Event';
export const SET_SELECTED_SAFETY_INCIDENT = 'Safety: Set selected safety incident';
export const SET_GATEWAY_ID = 'Safety: Set GateWay ID';
export const RESET_SAFETY_STORE = 'Safety: Reset Safety Store';
export const SET_MAP_BLUEPRINT_LOADING = 'Safety: Set Map Blueprint Loader';
export const SET_MAP_WORKERS = 'Safety: Set Map Workers';
export const SET_MAP_WORKERS_LOADING = 'Safety: Set Workers Loading';
export const SET_MAP_ALL_WORKERS = 'Safety: fetch all map workers';
export const SET_MAP_FILTERED_FLOOR = 'Safety: filtered floors';
export const SET_MAP_FILTERED_ZONE = 'Safety: Safe Zones';
export const SET_MAP_FILTERED_ASSEMBLY_ZONE = 'Safety: Safe Assembly Zones';
export const SET_MAP_EVACUATION_PROGRESS = 'Safety: Evacuation in progress';
export const SET_MAP_EVACUATION_STARTAT = 'Safety: Evacuation start At';
export const SET_MAP_EVACUATION_ID = 'Safety: Evacuation uuid';
export const SET_MAP_EVACUATION_LOADING = 'Safety: Evacuation Loading';
export const SET_MAP_LOCATION_UPDATE = 'Safety: Evacuation location update';
export const SET_MAP_EVACUATION_FLOORS_IDS = 'Safety: SET_MAP_EVACUATION_FLOORS_IDS';

/**
 * Productivity
 */

export const SET_PRODUCTIVITY_LIST_TOGGLE = 'Productivity List Toggle';

export const ADD_PLANNING_ANCHOR_REQ = 'Anchors: ADD_PLANNING_ANCHOR_REQ';
export const ADD_PLANNING_ANCHOR_SUCCESS = 'Anchors: ADD_PLANNING_ANCHOR_SUCCESS';
export const ADD_PLANNING_ANCHOR_FAILED = 'Anchors: ADD_PLANNING_ANCHOR_FAILED';

export const DELETE_PLANNING_ANCHOR_REQ = 'Anchors: DELETE_PLANNING_ANCHOR_REQ';
export const DELETE_PLANNING_SUCCESS = 'Anchors: DELETE_PLANNING_SUCCESS';
export const DELETE_PLANNING_FAILED = 'Anchors: DELETE_PLANNING_FAILED';

export const UPDATE_PLANNING_ANCHOR_LOCATION = 'Anchors: UPDATE_PLANNING_ANCHOR_LOCATION';
export const RESET_PLANNING_ANCHORS = 'Anchors: RESET_PLANNING_ANCHORS';

/**
 * Daily Attendance Report Reducer Action Types
 */
export const DAILY_ATTENDANCE_REPORT_UPDATE_SEARCH_PARAMS =
  'Daily Attendance Report: Update Search Params';
export const DAILY_ATTENDANCE_REPORT_ADD_ALL_WORKERS = 'Daily Attendance Report: Add All Workers';
export const DAILY_ATTENDANCE_REPORT_ALL_WORKERS_LOADING =
  'Daily Attendance Report: Add All Workers Loading';
export const DAILY_ATTENDANCE_REPORT_ALL_WORKERS_FETCHED =
  'Daily Attendance Report: Add All Workers Fetched';
export const DAILY_ATTENDANCE_REPORT_PROJECT_ID = 'Daily Attendance Report: Project ID Fetched';
export const DAILY_ATTENDANCE_REPORT_WORKERS_DATA_LOADING =
  'Daily Attendance Report: Workers Data Loading';
export const DAILY_ATTENDANCE_REPORT_STATE_RESTORE = 'Daily Attendance Report: Reset Store Data';

/**
 * Maps Reducer Action Types
 */
export const MAPS_UPDATE_SEARCH_PARAMS = 'Maps: Update Search Params';
export const MAPS_ADD_ALL_WORKERS = 'Maps: Add All Workers';
export const MAPS_ALL_WORKERS_LOADING = 'Maps: Add All Workers Loading';
export const MAPS_ALL_WORKERS_FETCHED = 'Maps: Add All Workers Fetched';
export const MAPS_PROJECT_ID = 'Maps: Project ID Fetched';
export const MAPS_WORKERS_DATA_LOADING = 'Maps: Workers Data Loading';
export const MAPS_STATE_RESTORE = 'Maps: Reset Store Data';

/**
 * Assets Reducer Action Types
 */
export const ASSETS_SET_STATUSES_COUNTS = 'Assets: ASSETS_SET_STATUSES_COUNTS';
export const ASSETS_RESET_STATUSES_COUNTS = 'Assets: ASSETS_RESET_STATUSES_COUNTS';
