export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTER = '/register/:signature';
export const APP = '/';
export const ANALYTICS = '/analytics';
export const WORKERS = '/workers';
export const NODES = '/nodes';
export const CREWS = '/crews';
export const WORKSHIFTS = '/workshifts';
export const INSTALLATION = '/installation';
export const USER_MANAGEMENT = '/users';
export const MAINTENANCE = '/maintenance';
export const MAINTENANCE_MAP = `${MAINTENANCE}/map`;
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const CONFIGURATION = '/configuration';
export const CONFIGURATION_WORKER_ROLES = `${CONFIGURATION}/workerRoles`;
export const CONFIGURATION_SUB_CONTRACTORS = `${CONFIGURATION}/subContractors`;
export const CONFIGURATION_BULK_UPLOADS = `${CONFIGURATION}/bulkUploads`;
export const MAPS_ZONES = '/';
export const PRODUCTIVITY = '/productivity';
export const SAFETY_DEMO = '/safteyDemo';
export const SAFETY = '/safety';
export const SAFETY_EVACUATION = `/safetyevacuation`;
export const NOT_ACCESS = '/403';
export const NOT_FOUND = '/404';
export const MAPS = '/maps';
export const MAPS_SPAGHETTI = '/maps/spaghetti';
export const MAPS_PLAYBACK = '/maps/playback';
export const VIDEOAI = '/video-ai';
export const VIDEOAI_CAMERA = '/camera';
export const VIDEOAI_GALLERY = '/gallery';
export const VIDEOAI_REPORTS = '/reports';
export const DAILY_ATTENDANCE_REPORT = '/dailyAttendanceReport';
export const MAPS_HEATMAP = '/analytics/heatmap';
export const ANALYTICS_SPAGHETTI = '/analytics/spaghetti';
export const ANALYTICS_PLAYBACK = '/analytics/playback';
