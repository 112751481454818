import LOCALES from 'utils/locales';
import {
  sideMenu,
  button,
  labels as label,
  placeHolders as placeHolder,
  title,
  errors as error,
  messages as message,
  text,
} from '../label';

const sideMenuLabels = {
  [sideMenu.analytics]: 'الإحصائيات',
  [sideMenu.workers]: 'العمال',
  [sideMenu.installationMode]: 'قسم التركيبات',
  [sideMenu.userManagement]: 'إدارة المستخدمين',
  [sideMenu.configurations.title]: 'الإعدادات',
  [sideMenu.configurations.workerRoles]: 'تعديل الحرف',
  [sideMenu.configurations.subContractors]: 'المقاولون',
  [sideMenu.mapsZones]: 'الخرائط والمناطق',
  [sideMenu.crewsWorkshifts]: 'الفرق والورديات',
  [sideMenu.crews]: 'فرق العمل',
  [sideMenu.workshifts]: 'الورديات',
  [sideMenu.maintance.title]: 'Maintenance Kit',
  [sideMenu.maintance.mapView]: 'Map View',
  [sideMenu.maintance.mapViewTitle]: 'Maintenance Toolkit',
  [sideMenu.videoAI]: 'Video AI',
  [sideMenu.videoAICamera]: 'Cameras',
  [sideMenu.videoAIGallery]: 'Incident Gallery',
  [sideMenu.videoAIReports]: 'Incident & Reports',
};

const buttonLabels = {
  [button.create]: 'إنشاء',
  [button.cancel]: 'إلغاء',
  [button.update]: 'تحديث',
  [button.logout]: 'تسجيل الخروج',
  [button.proceed]: 'استمرار',
  [button.upload]: 'رفع',
  [button.uploadExcel]: 'رفع الملف',
  [button.reUploadExcel]: 'إعادة رفع الملف',
  [button.addNewClient]: 'إضافة عميل',
  [button.yes]: 'نعم',
  [button.no]: 'لا',
  [button.confirm]: 'تأكيد',
  [button.add]: 'اضف',
  [button.continue]: 'Continue',
};

const labels = {
  [label.name]: 'الإسم',
  [label.role]: 'الحرفة',
  [label.mobileNumber]: 'رقم الجوال',
  [label.roleName]: 'اسم الحرفة',
  [label.subcontractorName]: 'اسم المقاول',
  [label.helmet]: 'الخوذة',
  [label.helmetColor]: 'لون الخوذة',
  [label.workerTagId]: 'الرقم التعريفي للعامل',
  [label.contractor]: 'المقاول',
  [label.selectLanguage]: 'اختيار اللغة',
  [label.clientName]: 'اسم العميل',
  [label.address]: 'العنوان',
  [label.crew]: 'فريق العمل',
  [label.workshift]: 'الوردية',
  [label.crewDetails]: 'تفاصيل فريق العمل',
  [label.workshiftDetails]: 'تفاصيل الوردية',
  [label.dateFrom]: 'تاريخ البدأ',
};

const placeHolders = {
  [placeHolder.name]: 'ادخل الإسم',
  [placeHolder.mobile]: 'ادخل رقم الجوال',
  [placeHolder.roleName]: 'ادخل اسم الحرف',
  [placeHolder.workerName]: 'اسم العامل',
  [placeHolder.selectRole]: 'اختر الحرفة',
  [placeHolder.selectHelmet]: 'اختر الخوذة',
  [placeHolder.selectContractor]: 'اختر المقاول',
  [placeHolder.selectSubContractor]: 'Select Subcontractor',
  [placeHolder.tagId]: 'الرقم التعريفي',
  [placeHolder.selectClient]: 'اختر عميل',
  [placeHolder.selectSite]: 'اختر موقع',
  [placeHolder.selectClient]: 'اختر العميل',
  [placeHolder.selectSite]: 'اختر المشروع',
  [placeHolder.selectBuilding]: 'اختر البناية',
  [placeHolder.workshiftName]: 'ادخل اسم الوردية',
  [placeHolder.category]: 'اختر الفئة',
  [placeHolder.crewName]: 'ادخل اسم الفريق',
  [placeHolder.crewId]: 'ادخل رقم الفريق التعريفي',
  [placeHolder.crew]: 'اختر الفريق',
  [placeHolder.workshift]: 'اختر الوردية',
  [placeHolder.selectHelmetId]: 'اختر رقم الخوذة',
  [placeHolder.searchWorkshiftName]: 'اسم الوردية',
  [placeHolder.searchWorkers]: 'ابحث باسم العامل أو الرقم التعريفي',
  [placeHolder.filterByContractor]: 'تصفية بالمتعهد',
  [placeHolder.filterByRole]: 'تصفية بالحرفة',
  [placeHolder.filterByCrew]: 'تصفية بالفريق',
  [placeHolder.filterByStatus]: 'تصفية بالحالة',
  [placeHolder.searchCrews]: 'Crew Name/ID',
  [placeHolder.searchZones]: 'Search Zones',
  [placeHolder.searchNodes]: 'Search Nodes',
};

const titles = {
  [title.worker]: 'العامل',
  [title.addWorker]: 'اضف عامل',
  [title.workerRole]: 'حرفة العامل',
  [title.addWorkerRole]: 'اضف حرفة',
  [title.subcontractor]: 'المقاول',
  [title.addSubcontractor]: 'اضف مقاول',
  [title.createClient]: 'إضافة عميل',
  [title.workerName]: 'اسم العامل',
  [title.role]: 'الحرفة',
  [title.tagId]: 'الرقم التعريفي',
  [title.helmetID]: 'رقم الخوذة',
  [title.building]: 'المبنى',
  [title.floor]: 'المستوى',
  [title.zone]: 'المنطقة',
  [title.lastSeen]: 'آخر ظهور',
  [title.actions]: 'الإجراءات',
  [title.crew]: 'الفريق',
  [title.workShift]: 'الوردية',
  [title.joinedDate]: 'تاريخ البدء',
  [title.location]: 'الموقع',
  [title.site]: 'الموقع',
  [title.quickStats]: 'إحصاءات مختصرة',
  [title.totalWorkers]: 'مجموع العمال',
  [title.deployedHelmets]: 'جميع الخوذ',
  [title.assignedHelments]: 'الخوذ المسندة',
  [title.onlineHelmets]: 'خوذة متصلة مسندة',
  [title.activeHelmets]: 'خوذة فعالة',
  [title.inactiveHelmets]: 'خوذة ساكنة',
  [title.withoutHelmets]: 'بدون خوذة',
  [title.allDeployedHelmets]: 'كل الخوذ في الموقع',
  [title.assignedHelmetsOnly]: 'الخوذ المسندة فقط',
  [title.onlineHelmetsInfo]: 'مجموع الخوذ المتصلة',
  [title.activeHelmetsInfo]: 'الخوذ الفعالة',
  [title.inactiveHelmetsInfo]: 'الخوذ الساكنة',
  [title.trackWorkers]: 'تابع العمال',
  [title.active]: 'نشط',
  [title.inactive]: 'غير نشط',
  [title.return]: 'عودة',
  [title.zoom]: 'تقريب',
  [title.zoneName]: 'اسم المنطقة',
  [title.addWorkshift]: 'اضف وردية',
  [title.workshiftName]: 'اسم الوردية',
  [title.category]: 'الفئة',
  [title.nonWorkingDays]: 'يوم إجازة',
  [title.duration]: 'المدة',
  [title.startTime]: 'وقت البداية',
  [title.endTime]: 'وقت النهاية',
  [title.breakStartTime]: 'وقت بداية الإستراحة',
  [title.breakEndTime]: 'وقت نهاية الإستراحة',
  [title.breakStartTimeWithNumber]: 'استراحة {name} وقت البداية',
  [title.breakEndTimeWithNumber]: 'استراحة {name} وقت النهاية',
  [title.addCrew]: 'اضف فريق',
  [title.crewId]: 'الرقم التعريفي للفريق',
  [title.crewName]: 'اسم الفريق',
  [title.editWorker]: 'تعديل بيانات العامل',
  [title.assignHelmet]: 'اضف خوذة',
  [title.unAssignHelmet]: 'احذف خوذة',
  [title.breaks]: 'الإستراحات',
  [title.noWorkers]: 'لايوجد عمال في الفريق',
  [title.locatedOn]: 'موقع العامل',
  [title.workshiftDetails]: 'تفاصيل الوردية',
  [title.bulkUploadTitle]: 'رفع ملف العمال',
  [title.downloadTemplate]: 'تحميل ملف العمال',
  [title.clearTrack]: 'امسح التعقب',
  [title.createdAt]: 'Created Date',
  [title.numberOfWorkers]: 'Number of Workers',
  [title.editWorkerRole]: 'Edit Worker Role',
  [title.bulkAssignCrewShifts]: 'Bulk Upload | Assignments to Workshifts and Crews',
  [title.uploadReportedHours]: 'Upload Reported Hours',
  [title.reportedWorkHours]: 'Bulk Upload | Reported Hours for Workers',
  [title.delays]: 'Delays ({count})',
};

const texts = {
  [text.roles]: 'الحرف',
  [text.contractors]: 'المقاول',
  [text.helmets]: 'الخوذة',
  [text.sites]: 'المواقع',
  [text.clients]: 'العملاء',
  [text.workers]: 'العمال',
  [text.nA]: '-لايوجد بيانات-',
  [text.infoMessage1Hour]: 'البيانات من الساعة الماضية',
  [text.floors]: 'المستويات',
  [text.buildings]: 'البنايات',
  [text.crews]: 'الفرق',
  [text.workshifts]: 'الورديات',
  [text.subcontractors]: 'subcontractors',
};

const errors = {
  [error.required]: '{name} مطلوب',
  [error.min]: `الإسم يجب أن يحتوي على {name} أحرف على الأقل`,
  [error.max]: `الإسم يجب أن يحتوي على {name} أحرف على الأكثر`,
  [error.minDigits]: `هذا {name} يقبل {val} حرف على الأكثر`,
  [error.maxDigits]: `هذا {name} يقبل {val} على الأقل`,
  [error.onlyDigits]: '{name} يقبل أعداد فقط',
  [error.image]: `اختر صورة من امتداد PNG, JPG, JPEG, فقط، ويجب ألا تزيد مساحتها عن ٣ ميجا بايت`,
  [error.exceedDuration]: `مدة الإستراحة لايمكن أن تتجاوز مدة الوردية`,
  [error.sequential]: `الإستراحات يجب أن تكون متتالية`,
  [error.conflicting]: `لايجب أن تكون الإستراحات متداخلة مع بعضها البعض`,
  [error.fallOutSide]: `وقت بداية الإستراحة لايمكن أن يصبح مخالف لوقت الوردية`,
  [error.uploadSizeExceed]: `حجم الملف لايمكن أن يتعدى ١٠ ميجا بايت`,
  [error.uploadType]: `نوع الملف غير مدعوم`,
};

const messages = {
  [message.created]: 'تم الإنشاء بنجاح',
  [message.confirm]: 'أنت على وشك حذف جميع التعديلات. هل أنت متأكد؟',
  [message.dataNA]: 'لايوجد اسم مدخل',
  [message.unknowError]: 'حدث خطأ ما، برجاء الإتصال بالدعم الفني',
  [message.tryAgain]: 'حدث خطأ ما، برجاء المحاولة لاحقا',
  [message.noInternet]: 'لايوجد اتصال بالإنترنت، برجاء التحقق من اتصالك بالإنترنت ثم عاود المحاولة',
  [message.internetBack]: `تم الإتصال بالإنترنت`,
  [message.deleteSuccess]: 'تم حذف {name} بنجاح',
  [message.deleteConfirm]: 'هل أنت متأكد من حذف ،العامل لايمكن تعديل الإجراء بعد ذلك؟',
  [message.unableToload]: 'لايمكن تحميل الصورة',
  [message.pleaseTryAgain]: 'أعد المحاولة',
  [message.unAssignConfim]: 'هل أنت متأكد من حذف الخوذة',
  [message.assigned]: 'تم إسناد الخوذة بنجاح',
  [message.uploadSuccess]: 'تم الرفع بنجاح',
};

export default {
  [LOCALES.ARABIC]: {
    ...sideMenuLabels,
    ...buttonLabels,
    ...labels,
    ...placeHolders,
    ...titles,
    ...texts,
    ...errors,
    ...messages,
  },
};
