export type Worker = {
  id: number;
  image?: {
    url: string;
    status: string;
  };
  building?: string;
  floor?: string;
  helmet?: {
    color?: string;
    wnt_inventory_id: number;
  };
  last_seen?: any;
  name: string;
  worker_role: string;
  worker_tag_id: string;
  zone?: string;
};

export type WorkersStore = {
  fetchWorkers: boolean;
  isViewModal: boolean;
  isCreateModal: boolean;
  workerDetails: {
    isLoading: boolean;
    data: Worker | null;
  };
  onlineWorkers: {
    isLoading: boolean;
    data: any[];
    activeCount: number;
    inActiveCount: number;
  };
  totalWorkers: any;
  editWorker: Worker | null;
  lastUpdateAt: Date | null;
};

const initialState: WorkersStore = {
  fetchWorkers: false,
  isViewModal: false,
  isCreateModal: false,
  workerDetails: {
    isLoading: false,
    data: null,
  },
  onlineWorkers: {
    isLoading: false,
    data: [],
    activeCount: 0,
    inActiveCount: 0,
  },
  totalWorkers: null,
  editWorker: null,
  lastUpdateAt: null,
};

export default initialState;
