export const sideMenu = {
  analytics: 'sideMenu.analytics',
  workers: 'sideMenu.workers',
  mapsZones: 'sideMenu.mapsZones',
  productivity: 'sideMenu.productivity',
  crewsWorkshifts: 'sideMenu.crewsWorkshifts',
  crews: 'sideMenu.crews',
  workshifts: 'sideMenu.workshifts',
  nodes: 'sideMenu.nodes',
  installationMode: 'sideMenu.installationMode',
  maintance: {
    title: 'sidemenu.maintance.title',
    mapView: 'sidemenu.maintance.mapView',
    mapViewTitle: 'sidemenu.maintance.mapViewTitle',
  },
  userManagement: 'sideMenu.userManagement',
  configurations: {
    title: 'sideMenu.configurations.title',
    workerRoles: 'sideMenu.configurations.workerRoles',
    subContractors: 'sideMenu.configurations.subContractors',
    bulkUploads: 'sideMenu.configurations.bulkUploads',
  },
  safetyDemo: 'sideMenu.safetyDemo',
  electricalRmInstallation: 'sideMenu.electricalRmInstallation',
  safety: 'sideMenu.safety',
  evacuation: 'sideMenu.evacuation',
  videoAI: 'sideMenu.videoAI',
  videoAICamera: 'sideMenu.videoAICamera',
  videoAIGallery: 'sideMenu.videoAIGallery',
  videoAIReports: 'sideMenu.videoAIReports',
  dailyAttendanceReport: 'sideMenu.dailyAttendanceReport',
};

export const button = {
  create: 'button.create',
  cancel: 'button.cancel',
  update: 'button.update',
  logout: 'button.logout',
  proceed: 'button.proceed',
  upload: 'button.upload',
  uploadExcel: 'button.uploadExcel',
  reUploadExcel: 'button.reUploadExcel',
  addNewClient: 'button.addNewClient',
  yes: 'button.yes',
  no: 'button.no',
  confirm: 'button.confirm',
  add: 'button.add',
  continue: 'button.continue',
  startEvacuation: 'button.startEvacuation',
  stopEvacuation: 'button.stopEvacuation',
  submit: 'button.submit',
};

export const labels = {
  name: 'label.name',
  role: 'label.role',
  mobileNumber: 'label.mobileNumber',
  roleName: 'label.roleName',
  subcontractorName: 'label.subcontractorName',
  helmet: 'label.helmet',
  helmetColor: 'label.helmetColor',
  workerTagId: 'label.workerTagId',
  contractor: 'label.contractor',
  selectLanguage: 'label.selectLanguage',
  clientName: 'label.clientName',
  address: 'label.address',
  crew: 'label.crew',
  crewDetails: 'label.crewDetails',
  workshiftDetails: 'label.workshiftDetails',
  workshift: 'label.workshift',
  dateFrom: 'label.dateFrom',
  downloadTemplate: 'label.downloadTemplate',
  activity: 'label.activity',
  siteEngineer: 'label.siteEngineer',
  foreMan: 'label.foreMan',
  dateTo: 'label.dateTo',
  downloadCreateCrewTemplate: 'label.downloadCreateCrewTemplate',
};

export const placeHolders = {
  name: 'placeholder.name',
  mobile: 'placeholder.mobile',
  roleName: 'placeholder.roleName',
  workerName: 'placeholder.workerName',
  selectRole: 'placeholder.selectRole',
  selectHelmet: 'placeholder.selectHelmet',
  selectHelmetId: 'placeholder.selectHelmetId',
  selectContractor: 'placeholder.selectContractor',
  selectSubContractor: 'placeholder.selectSubContractor',
  selectSite: 'placeholder.selectSite',
  tagId: 'placeholder.tagId',
  selectClient: 'placeholder.selectClient',
  selectBuilding: 'placeholder.selectBuilding',
  workshiftName: 'placeholder.workshiftName',
  category: 'placeholder.category',
  crewName: 'placeholder.crewName',
  crewId: 'placeholder.crewId',
  crew: 'placeholder.crew',
  workshift: 'placeholder.workshift',
  searchWorkshiftName: 'placeholder.searchWorkshiftName',
  searchWorkers: 'placeholder.searchWorkers',
  filterByBuilding: 'placeholder.filterByBuilding',
  filterByContractor: 'placeholder.filterByContractor',
  filterByRole: 'placeholder.filterByRole',
  filterByCrew: 'placeholder.filterByCrew',
  filterByStatus: 'placeholder.filterByStatus',
  filterByContractorGroup: 'placeholder.filterByContractorGroup',
  filterByRoleGroup: 'placeholder.filterByRoleGroup',
  filterByCrewsGroup: 'placeholder.filterByCrewsGroup',
  searchCrews: 'placeholder.searchCrews',
  searchZones: 'placeHolder.searchZones',
  searchNodes: 'placeHolder.searchNodes',
  selectSafeZone: 'placeHolder.selectSafeZone',
  selectFloor: 'placeHolder.selectFloor',
  selectZone: 'placeHolder.selectZone',
  selectAssemblyPoint: 'placeHolder.selectAssemblyPoint',
};

export const title = {
  worker: 'title.worker',
  addWorker: 'title.addWorker',
  editWorker: 'title.editWorker',
  workerRole: 'title.workerRole',
  addWorkerRole: 'title.addWorkerRole',
  editWorkerRole: 'title.editWorkerRole',
  // SubContracor
  subcontractor: 'title.subcontractor',
  addSubcontractor: 'title.addSubcontractor',
  editSubcontractor: 'title.editSubcontractor',
  createClient: 'title.createClient',
  workerName: 'title.workerName',
  role: 'title.role',
  tagId: 'title.tagId',
  helmetID: 'title.helmetID',
  building: 'title.building',
  floor: 'title.floor',
  zone: 'title.zone',
  lastSeen: 'title.lastSeen',
  actions: 'title.actions',
  crew: 'title.crew',
  workShift: 'title.workShift',
  joinedDate: 'title.joinedDate',
  joinDate: 'title.joinDate',
  location: 'title.location',
  locationGroupName: 'title.locationGroupName',
  site: 'title.site',
  quickStats: 'title.quickStats',
  totalWorkers: 'title.totalWorkers',
  deployedHelmets: 'title.deployedHelmets',
  assignedHelments: 'title.assignedHelments',
  onlineHelmets: 'title.onlineHelmets',
  activeHelmets: 'title.activeHelmets',
  inactiveHelmets: 'title.inactiveHelmets',
  withoutHelmets: 'title.withoutHelmets',
  allDeployedHelmets: 'title.allDeployedHelmets',
  assignedHelmetsOnly: 'title.assignedHelmetsOnly',
  onlineHelmetsInfo: 'title.onlineHelmetsInfo',
  activeHelmetsInfo: 'title.activeHelmetsInfo',
  inactiveHelmetsInfo: 'title.inactiveHelmetsInfo',
  totalAssets: 'title.totalAssets',
  totalAnchors: 'title.totalAnchorts',
  totalSinks: 'title.totalSinks',
  onlineAssets: 'title.onlineAssets',
  onlineAnchors: 'title.onlineAnchors',
  onlineSinks: 'title.onlineSinks',
  trackWorkers: 'title.trackWorkers',
  active: 'title.active',
  inactive: 'title.inactive',
  return: 'title.return',
  zoom: 'title.zoom',
  zoneName: 'title.zoneName',
  addWorkshift: 'title.addWorkshift',
  workshiftName: 'title.workshiftName',
  category: 'title.category',
  nonWorkingDays: 'title.nonWorkingDays',
  duration: 'title.duration',
  startTime: 'title.startTime',
  endTime: 'title.endTime',
  breakStartTime: 'title.breakStartTime',
  breakStartTimeWithNumber: 'title.breakStartTimeWithNumber',
  breakEndTimeWithNumber: 'title.breakEndTimeWithNumber',
  breakEndTime: 'title.breakEndTime',
  addCrew: 'title.addCrew',
  crewId: 'title.crewId',
  crewName: 'title.crewName',
  assignHelmet: 'title.assignHelmet',
  unAssignHelmet: 'title.unAssignHelmet',
  breaks: 'title.breaks',
  noWorkers: 'title.noWorkers',
  locatedOn: 'title.locatedOn',
  workshiftDetails: 'title.workshiftDetails',
  bulkUploadTitle: 'title.bulkUploadTitle',
  bulkAssignCrewShifts: 'title.bulkAssignCrewShifts',
  downloadTemplate: 'title.downloadTemplate',
  downloadCrewWorkshiftTemplate: 'title.downloadCrewWorkshiftTemplate',
  clearTrack: 'title.clearTrack',
  createdAt: 'title.createdAt',
  numberOfWorkers: 'title.numberOfWorkers',
  toolName: 'title.toolName',
  locationGroup: 'title.locationGroup',
  locationGroupTagID: 'title.locationGroupTagID',
  template: 'title.template',
  subcontractorsGroup: 'title.subcontractorsGroup',
  download: 'title.download',
  bulkSubcontractorsGroup: 'title.bulkSubcontractorsGroup',
  crewsGroup: 'title.crewsGroup',
  bulkCrewsGroup: 'title.bulkCrewsGroup',
  locationGroupAssignment: 'title.locationGroupAssignment',
  workerRolesGroup: 'title.workerRolesGroup',
  updateWorkerStatus: 'title.updateWorkerStatus',
  uploadReportedHours: 'title.uploadReportedHours',
  reportedWorkHours: 'title.reportedWorkHours',
  reportedHours: 'title.reportedHours',
  assignCrewManager: 'title.assignCrewManager',
  assignCrewToCrewManager: 'title.assignCrewToCrewManager',
  downloadCrewAssignmentTemplate: 'title.downloadCrewAssignmentTemplate',
  date: 'title.date',
  siteEngineerName: 'title.siteEngineerName',
  foremanName: 'title.foremanName',
  sectionDescription: 'title.sectionDescription',
  activityCode: 'title.activityCode',
  activityCodes: 'title.activityCodes',
  activityDescription: 'title.activityDescription',
  uom: 'title.uom',
  targetQTY: 'title.targetQTY',
  plannedQTY: 'title.plannedQTY',
  targetRate: 'title.targetRate',
  targetHours: 'title.targetHours',
  targetQuantity: 'title.targetQuantity',
  bookedHours: 'title.bookedHours',
  actualRate: 'title.actualRate',
  actualQTY: 'title.actualQTY',
  actualProductivityRate: 'title.actualProductivityRate',
  gainLossHours: 'title.gainLossHours',
  delayCodes: 'title.delayCodes',
  delayLostHours: 'title.delayLostHours',
  status: 'title.status',
  delays: 'title.delays',
  reason: 'title.reason',
  hoursLost: 'title.hoursLost',
  notes: 'title.notes',
  resetPassword: 'Reset Password',
  lastActive: 'title.lastActive',
  helmetAssignedDate: 'title.helmetAssignedDate',
  lastUpdatedDate: 'title.lastUpdatedDate',
  confirmApproval: 'title.confirmApproval',
  totalWorkingHours: 'title.totalWorkingHours',
  configureWorkingHours: 'title.configureWorkingHours',
  nonWorkingDay: 'title.nonWorkingDay',
  networkId: 'title.networkId',
  nodeId: 'title.nodeId',
  nodeType: 'title.nodeType',
  bufferUsage: 'title.bufferUsage',
  linkQuality: 'title.linkQuality',
  batteryVoltage: 'title.batteryVoltage',
  updatedAt: 'title.updatedAt',
  locationUpdatedAt: 'title.locationUpdatedAt',
  displacementError: 'title.displacementError',
  activeInactive: 'title.activeInactive',
  locationGroupCode: 'title.locationGroupCode',
  unitOfMeasure: 'title.unitOfMeasure',
  targetRatee: 'title.targetRatee',
  crewGroupName: 'title.crewGroupName',
  workerRoleGroup: 'workerRoleGroup',
  remarks: 'remarks',
  workerStatus: 'title.workerStatus',
  subcontractorGroup: 'title.subcontractorGroup',
  reported: 'title.reported',
  bulkUploadCrew: 'title.bulkUploadCrew',
  manualLocationAssignment: 'title.manualLocationAssignment',
};

export const text = {
  roles: 'text.roles',
  contractors: 'text.contractors',
  helmets: 'text.helmets',
  sites: 'text.sites',
  clients: 'text.clients',
  workers: 'text.workers',
  nA: 'text.nA',
  infoMessage1Hour: 'text.infoMessage1Hour',
  floors: 'text.floors',
  buildings: 'text.buildings',
  crews: 'text.crews',
  workshifts: 'text.workshifts',
  analytics: 'text.analytics',
  subcontractors: 'text.subcontractors',
  subcontractorgroups: 'text.subcontractorgroups',
  rolegroups: 'text.rolegroups',
  crewgroups: 'text.crewgroups',
  zones: 'text.zones',
  productivity: 'text.productivity',
  actualHours: 'text.actualHours',
  bookedHours: 'text.bookedHours',
  lostHours: 'text.lostHours',
  tagIds: 'text.tagIds',
  inventories: 'text.inventories',
};

export const errors = {
  required: 'error.required',
  min: 'error.min',
  max: 'error.max',
  minDigits: 'error.minDigits',
  maxDigits: 'error.maxDigits',
  onlyDigits: 'error.onlyDigits',
  image: 'error.image',
  exceedDuration: 'error.exceedDuration',
  sequential: 'error.sequential',
  conflicting: 'error.conflicting',
  fallOutSide: 'error.fallOutSide',
  uploadSizeExceed: 'error.uploadSizeExceed',
  uploadType: 'error.uploadType',
  alreadyExist: 'error.alreadyExist',
};

export const messages = {
  created: 'message.created',
  confirm: 'message.confirm',
  dataNA: 'message.dataNA',
  unknowError: 'message.unknowError',
  tryAgain: 'message.tryAgain',
  noInternet: 'message.noInternet',
  internetBack: 'message.internetBack',
  deleteConfirm: 'message.deleteConfirm',
  deleteSuccess: 'message.deleteSuccess',
  unableToload: 'message.unableToload',
  pleaseTryAgain: 'message.pleaseTryAgain',
  unAssignConfim: 'message.unAssignConfirm',
  assigned: 'message.assigned',
  uploadSuccess: 'message.uploadSuccess',
  approveMessage: 'message.approveMessage',
};
